import api from '@/api/positions'

export default {
	name: 'Admin',
	data () {
		return {
			limit: 10,
			page: 1
		}
	},
	created() {
		this.getData()
	},
	methods: {
		getData() {
			api.getPagination(this, this.offset, this.limit)
		},
		remove(id) {
			this.$root.$emit('ConfirmingModal', true, {
				title: "Delete item",
				text: "Sure delete this item?",
				action_title: 'Delete',
				action: () => {
					api.delete(this, id)
				}
			})
		},
		show (id) {
			this.$router.push({name: 'ShowPosition', params: {id: id}})
		},
		update (id) {
			this.$router.push({name: 'UpdatePosition', params: {id: id}})
		}
	},
	computed: {
		offset () {
			return this.limit * (this.page -1)
		},
		tableData () {
			if (this.state?.positions?.data) {
				return this.state.positions.data
			}
			return []
		},
		countButtons () {
			if (this.state?.positions?.meta) {
				return parseInt((this.state.positions.meta.count / this.limit) +1)
			}
			return 1
		}
	}
}
